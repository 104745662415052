import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import Project from "../components/Project";
import Seo from "../components/Seo";

const Grenade = () => (
  <Layout>
    <Seo
      title={"Snack revolution"}
      description={
        "How Grenade is inspiring healthy attitudes to snacking in China. 15M to > 200M RMB increase in available market."
      }
    />
    <Project type="grenade" />
    <Footer />
  </Layout>
);

export default Grenade;

export const pageQuery = graphql`
  query SnackRevolutionQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
