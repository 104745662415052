import React from "react";
import Solution from "./WhyUsBox";
import NavBar from "../components/nav";
import { Link } from "gatsby";
import I18nContext from "../lib/i18n-context";

const Project = (props) => {
  const { locale, currentLocale } = React.useContext(I18nContext);

  const [data, setData] = React.useState({});

  const caseStudies = locale.caseStudies;

  const whitespaceClass = currentLocale === "cn" ? "whitespace-nowrap" : "";

  React.useEffect(() => {
    if (props.type === "iron-women") {
      setData(caseStudies.ironWomen);
    } else if (props.type === "grenade") {
      setData(caseStudies.grenade);
    } else if (props.type === "nandos") {
      setData(caseStudies.nandos);
    }
  }, [data]);

  return (
    <>
      <header>
        <NavBar
          navClass={"white-navbar"}
          logo={"/assets/main-logo-blue.svg"}
          class={"text-darkblue border-darkblue"}
        />
        {/* HEADER  */}
        <section
          className="w-full mt-32 bg-center bg-contain bg-no-repeat text-darkblue"
          style={{ backgroundImage: `url(${"/assets/ideas-bg-cream.svg"})` }}
        >
          <div className="container max-w-screen-half-xl text-darkblue px-10">
            <div className="flex flex-col sm:justify-evenly sm:items-center sm:flex-row font-arima space-y-10 lg:px-28 xl:px-40 py-10 border-b-2">
              <div
                className={`space-y-1 w-full sm:w-1/3 lg:w-1/3 ${data.headerClass}`}
              >
                <h4 className="xl:text-xl"> {caseStudies.title} </h4>
                <h1 className="text-3xl lg:text-4xl xl:text-5xl">
                  {" "}
                  {data.projectName}{" "}
                </h1>
                <div>
                  <a href="#project">
                    <svg
                      className="h-2 mt-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.643"
                      height="15.736"
                      viewBox="0 0 28.643 15.736"
                    >
                      <path
                        id="Path_701"
                        data-name="Path 701"
                        d="M2301.307,677.7l13.615,13.615,13.615-13.615"
                        transform="translate(-2300.6 -676.996)"
                        fill="none"
                        stroke="#1e3d49"
                        strokeWidth="2"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="font-poppins w-full sm:w-2/3 lg:w-2/3 sm:px-10 xl:px-8 sm:pb-10 max-w-sm xl:text-lg">
                {data.projectDesc}
              </div>
            </div>
            <div
              id="project"
              className={`py-10 font-arima text-darkblue space-y-6 ${data.businessClass}`}
            >
              <h2 className="text-xl md:text-2xl xl:text-3xl">
                {caseStudies.business}
              </h2>
              <div className="grid sm:grid-cols-3 space-y-6 sm:space-y-0 lg:gap-6 lg:text-lg xl:text-xl xl:gap-16">
                <div className="border-l-2 pl-4">{data.businessOne}</div>
                <div className="border-l-2 pl-4">{data.businessTwo}</div>
                <div className="border-l-2 pl-4">{data.businessThree}</div>
              </div>
            </div>
          </div>
        </section>
      </header>
      <main className="max-w-screen-half-xl container px-8 py-10 lg:px-20 xl:px-24">
        {/* ABOUT */}
        <section className="w-full pb-16">
          {data.headerImg && (
            <div className={data.headerImgClass}>
              <div
                className={`absolute top-2 right-2 w-1/5 bg-white rounded-md ${data.logoAward ? data.logoAward : "hidden"
                  }`}
              >
                <img src="/assets/drum-award.png" alt="Drum award" />
              </div>
              <img
                className="w-full"
                src={data.headerImg}
                alt={data.headerAlt}
              />
            </div>
          )}
          {data.video && (
            <div
              style={{ paddingBottom: "50.7%" }}
              className="relative h-0 max-w-6xl mx-auto mb-10 md:mb-16"
            >
              <video
                controls
                poster="/assets/grenade-bar.png"
                className="absolute top-0 left-0 h-full w-full"
              >
                <source src={data.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          <div className="flex flex-col sm:flex-row sm:items-center space-y-10 sm:space-y-0 sm:space-x-6 lg:space-x-28">
            <div className="flex-1 relative">
              <img
                className="max-h-734 object-cover object-bottom w-full"
                src={data.modelImg}
                alt={data.modelAlt}
              />
              <div className="absolute top-2 right-2 w-2/6">
                <img src={data.babyLogo} alt="" />
              </div>
            </div>
            <div className="sm:flex-1 sm:flex sm:flex-col items-center text-darkblue">
              <ul className={data.aboutBoxClass}>
                <h2 className="font-arima text-2xl pb-4 lg:text-3xl xl:text-4xl ">
                  {" "}
                  {caseStudies.about} {data.aboutTitle}:{" "}
                </h2>
                <li className={`${currentLocale === 'cn' ? 'flex justify-between space-x-10' : 'grid grid-cols-6'} items-center border-t-2 py-2`}>
                  <h5 className={`xl:text-xl font-arima ${whitespaceClass} ${currentLocale === 'cn' ? '' : 'col-span-2'}`}>
                    {" "}
                    {data.aboutFirstTitle}{" "}
                  </h5>{" "}
                  <p
                    className={`text-xs xl:text-sm font-poppins ${whitespaceClass} ${data.grenadeAboutOne} ${currentLocale === 'cn' ? '' : 'col-span-4'}`}
                  >
                    {data.aboutFirstDesc}
                  </p>
                </li>
                <li className={`${currentLocale === 'cn' ? 'flex justify-between space-x-10' : 'grid grid-cols-6'} items-center border-t-2 py-2`}>
                  <h5 className={`xl:text-xl font-arima ${whitespaceClass} ${currentLocale === 'cn' ? '' : 'col-span-2'}`}>
                    {" "}
                    {data.aboutSecondTitle
                      ? data.aboutSecondTitle
                      : caseStudies.portfolio}
                  </h5>{" "}
                  <p
                    className={`text-xs xl:text-sm font-poppins ${whitespaceClass} ${data.grenadeAboutTwo} ${currentLocale === 'cn' ? '' : 'col-span-4'}`}
                  >
                    {data.aboutSecondDesc}
                  </p>
                </li>
                <li className={`${currentLocale === 'cn' ? 'flex justify-between space-x-10' : 'grid grid-cols-6'} items-center border-t-2 py-2`}>
                  <h5 className={`xl:text-xl font-arima ${whitespaceClass} ${currentLocale === 'cn' ? '' : 'col-span-2'}`}>
                    {" "}
                    {data.aboutThirdTitle
                      ? data.aboutThirdTitle
                      : caseStudies.export}{" "}
                  </h5>{" "}
                  <p
                    className={`text-xs xl:text-sm font-poppins ${whitespaceClass} ${data.grenadeAboutThree} ${currentLocale === 'cn' ? '' : 'col-span-4'}`}
                  >
                    {data.aboutThirdDesc}
                  </p>
                </li>
                {data.aboutFourthTitle && (
                  <li className={`${currentLocale === 'cn' ? 'flex justify-between space-x-10' : 'grid grid-cols-6'} items-center border-t-2 border-b-2 py-2`}>
                    <h5 className={`xl:text-xl font-arima ${whitespaceClass} ${currentLocale === 'cn' ? '' : 'col-span-2'}`}>
                      {" "}
                      {data.aboutFourthTitle}
                      {!data.fourthTitle ? "" : caseStudies.mission}
                    </h5>{" "}
                    <p
                      className={`text-xs xl:text-sm font-poppins ${whitespaceClass} ${data.grenadeAboutFour} ${currentLocale === 'cn' ? '' : 'col-span-4'}`}
                    >
                      {data.aboutFourthDesc}
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>
        {/* IMPERATIVE */}
        <section
          className={`w-full text-darkblue space-y-8 ${data.imperativeClass}`}
        >
          <div className="space-y-3">
            <h2 className="font-arima text-2xl lg:text-3xl xl:text-4xl">
              {" "}
              {caseStudies.change}{" "}
            </h2>
            <p className="font-poppins text-xs sm:text-sm lg:text-lg xl:text-2xl font-light">
              {data.changeDesc}
            </p>
          </div>
          <img className="w-full" src={data.changeImg} alt={data.changeAlt} />
          {/* GRENADE ONLY */}
          <div className={`${data.grenadeClassImgs}`}>
            <div className={`${data.orangeDivBox}`}>
              <img
                className="w-full max-w-xs"
                src={data.orangeImg}
                alt={data.orangeAlt}
              />
            </div>
            <div className={`${data.blueDivBox}`}>
              <img
                className="w-full max-w-xs"
                src={data.blueImg}
                alt={data.blueAlt}
              />
            </div>
          </div>
        </section>
        {/* OUR SOLUTION */}
        <section
          className={`w-full text-darkblue pb-16 grid ${data.solutionGridSpanClass} space-y-10 lg:space-y-0 place-items-center`}
        >
          <div
            className={`${data.solutionGridSpanOne} space-y-6 lg:pr-10 xl:pr-20`}
          >
            <h2 className="font-arima text-2xl lg:text-3xl xl:text-4xl">
              {" "}
              {caseStudies.solution}{" "}
            </h2>
            <div className={`grid ${data.solutionClass} gap-6`}>
              <Solution
                pClass={"xl:text-sm"}
                titleClass={"xl:text-2xl"}
                title={data.solutionTitleOne}
                desc={data.solutionDescOne}
              />
              <Solution
                pClass={"xl:text-sm"}
                titleClass={"xl:text-2xl"}
                title={data.solutionTitleSecond}
                desc={data.solutionDescTwo}
              />
              <Solution
                pClass={"xl:text-sm"}
                titleClass={"xl:text-2xl"}
                title={data.solutionTitleThird}
                desc={data.solutionDescThree}
              />
              <Solution
                pClass={"xl:text-sm"}
                titleClass={"xl:text-2xl"}
                title={data.solutionTitleFour}
                desc={data.solutionDescFour}
              />
            </div>
          </div>
          <div className="lg:col-span-1">
            <img src={data.solutionImg} alt={data.solutionAlt} />
          </div>
        </section>
        <section className={`${data.o2oSection}`}>
          <div>
            <img src={data.o2oFirstImg} alt={data.o2oFirstAlt} />
          </div>
          <div>
            <img src={data.o2oSecondImg} alt={data.o2oSecondAlt} />
          </div>
          <div>
            <img src={data.o2oThirdImg} alt={data.o2oThirdAlt} />
          </div>
        </section>
        <section className="w-full text-darkblue border-t-2 pt-6">
          <div className={`space-y-6 ${data.clientPaddingClass}`}>
            <h2 className="font-arima text-2xl lg:text-3xl xl:text-4xl">
              {" "}
              {caseStudies.feedback}{" "}
            </h2>
            <p className="font-arima sm:text-lg lg:text-xl xl:text-3xl">
              {data.firstQuotePara}
            </p>
            <p className="font-arima sm:text-lg lg:text-xl xl:text-3xl">
              {data.secondQuotePara}
            </p>
            <div>
              <h5 className="font-arima lg:text-lg xl:text-xl">
                {data.jobTitle}
              </h5>
              <h6 className="font-poppins text-xs sm:text-base xl:text-lg">
                {data.company}
              </h6>
            </div>
          </div>
          <div className={`${data.clientClass}`}>
            <div className="flex items-center justify-center">
              <img
                className="w-2/3 -mr-3 sm:-mr-5 md:-mr-7 lg:-mr-8 xl:-mr-10"
                src={data.clientImg}
                alt={data.clientAlt}
              />
              <img
                className="w-1/4 -mt-10 sm:-mt-14 md:-mt-16 lg:-mt-20 xl:-mt-28"
                src={data.clientPostCard}
                alt={data.clientPostCardAlt}
              />
            </div>
          </div>
        </section>
        <section className="w-full border-t-2 py-6 text-darkblue">
          <div className="flex justify-between">
            <div className="flex items-center">
              <svg
                className="h-2"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="15"
                viewBox="0 0 13 15"
              >
                <g
                  id="Polygon_56"
                  data-name="Polygon 56"
                  transform="translate(0 15) rotate(-90)"
                  fill="#1e3d49"
                >
                  <path
                    d="M 14.13429927825928 12.5 L 0.8657020330429077 12.5 L 7.500000476837158 1.00054931640625 L 14.13429927825928 12.5 Z"
                    stroke="none"
                  />
                  <path
                    d="M 7.500000476837158 2.001115798950195 L 1.731413841247559 12 L 13.26858711242676 12 L 7.500000476837158 2.001115798950195 M 7.500000476837158 0 L 15 13 L 9.5367431640625e-07 13 L 7.500000476837158 0 Z"
                    stroke="none"
                    fill="#707070"
                  />
                </g>
              </svg>
              <Link
                className="hover:underline font-poppins pl-4 text-xs font-light xl:text-sm"
                to="/"
              >
                {" "}
                {caseStudies.backButton}{" "}
              </Link>
            </div>
            <div>
              <Link to={data.nextProject ? data.nextProject : ''}>
                {" "}
                <button className="text-xs font-light border-darkblue border rounded-lg px-10 py-1 lg:px-14 lg:text-sm hover:bg-gray-500 hover:text-white">
                  {caseStudies.nextButton}
                </button>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Project;
