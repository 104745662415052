import React from 'react';

const WhyUsBox = props => (
  <div className={`space-y-3 ${props.class}`}>
    <h3 className={`text-xl font-arima  border-l-2 pl-3 ${props.titleClass}`}>{props.title}</h3>
    <p className={`font-poppins font-light text-xs md:text-sm lg:text-xs xl:text-xs pl-4 ${props.pClass}`}>{props.desc}</p>
  </div>
);

export default WhyUsBox;
